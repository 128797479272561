<template>
    <div class="root-wrap">
        <div class="body-wrap">
            <div class="fill-box">
                <div class="bg">
                    <img :src="require('@/assets/images/order/pay-bg.png')" style="width: 100%;height: 150px;">
                </div>
                <div class="pay-wrap">
                    <div class="status-bar">
                        <div class="status-title">
                            <van-icon :name="statusIcon" style="margin-right: 10px;"></van-icon>{{order.orderStatusText}}
                        </div>
                        <div class="status-time fx-row fx-center" style="margin-top: 20px;">
                            <span>需付款：￥<span style="font-size: 18px;">{{order.amount}}</span></span>
                            <van-count-down :time="order.remainTime" style="color: #fff;margin-left: 20px;" :auto-start="false" @finish="onFinish" ref="countdown">
                                <template #default="timeData">
                                    <span>剩余：{{timeData.minutes > 9 ? timeData.minutes : '0' + timeData.minutes}}</span>
                                    <span>:</span>
                                    <span>{{timeData.seconds > 9 ? timeData.seconds : '0' + timeData.seconds}}</span>
                                </template>
                            </van-count-down>
                        </div>
                    </div>
                    <div class="block">
                        <div class="fx-row">
                            <div style="margin-right: 20px;">
                                <img :src="order.logo" style="width: 60px;height: 60px;">
                            </div>
                            <div class="fx-column fx-between" style="padding: 8px 0;">
                                <div class="product-name">{{order.orderTitle}}</div>
                                <div class="product-origin">官方价&nbsp;￥{{order.officialPrice}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="block">
                        <div class="order-detail">
                            <div class="detail-name">充值账号</div>
                            <div class="detail-value"><span>{{order.chargeAccount}}</span><span v-if="order.chargeAccountAppend">{{ '，' + order.chargeAccountAppend }}</span></div>
                        </div>
                        <van-divider style="margin: 16px 0;" />
                        <div class="order-detail">
                            <div class="detail-name">下单编号</div>
                            <div class="detail-value">
                                <span>{{order.id}}</span>
                            </div>
                        </div>
                        <div class="order-detail" style="margin-top: 10px;">
                            <div class="detail-name">下单时间</div>
                            <div class="detail-value">
                                <span>{{order.addTime}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="block">
                        <div class="order-detail detail-grid">
                            <div class="detail-name">商品总额</div>
                            <div class="detail-value">￥{{order.amount}}</div>
                            <div></div>
                            <div class="detail-value">需付款：<span class="fc-status" style="font-weight: 700;">￥{{order.amount}}</span></div>
                        </div>
                    </div>
                    <div class="block" style="padding: 0;">
                        <van-radio-group v-model="payWay">
                            <van-cell-group>
                                <van-cell title="微信" center @click="payWay = 'wxPay'">
                                    <template #icon>
                                        <van-image class="cell-icon" :src="require('@/assets/images/order/wechat_pay.png')" fit="cover" width="36" style="margin-right: 15px;"></van-image>
                                    </template>
                                    <template #right-icon>
                                        <van-radio name="wxPay">
                                            <template #icon="props">
                                                <!-- 使用show 比if渲染更快 show:display:none-->
                                                <van-image v-show="props.checked" :src="require('@/assets/images/order/selected.png')" fit="cover" width="15"></van-image>
                                                <van-image v-show="!props.checked" :src="require('@/assets/images/order/select.png')" fit="cover" width="15"></van-image>
                                            </template>
                                        </van-radio>
                                    </template>
                                </van-cell>
                                <van-cell title="余额" center @click="payWay = 'accountPay'">
                                    <template #icon>
                                        <van-image class="cell-icon" :src="require('@/assets/images/order/balance_pay.png')" fit="cover" width="36" style="margin-right: 15px;"></van-image>
                                    </template>
                                    <template>
                                        <div style="margin-right: 10px;">剩余￥{{ memberInfo.memberAccountBalance }}</div>
                                    </template>
                                    <template #right-icon>
                                        <van-radio name="accountPay">
                                            <template #icon="props">
                                                <van-image v-show="props.checked" :src="require('@/assets/images/order/selected.png')" fit="cover" width="15"></van-image>
                                                <van-image v-show="!props.checked" :src="require('@/assets/images/order/select.png')" fit="cover" width="15"></van-image>
                                            </template>
                                        </van-radio>
                                    </template>
                                </van-cell>
                            </van-cell-group>
                        </van-radio-group>
                    </div>
                    <div class="block" v-if="order.note">
                        <div class="order-detail" style="align-items: start;margin-top: 10px;">
                            <div class="detail-name">注意事项</div>
                            <div class="detail-value" v-html="order.note" style="font-size: 10px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-wrap">
            <div class="action">
                <van-button round style="margin-right: 20px;padding: 10px 30px;" @click="cancelOrder">取消订单</van-button>
                <van-button round type="primary" @click="beforeOrderPay" style="padding: 10px 30px;">确认支付</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { cancelOrder } from '@api/order-request'
import { getOrderDetail } from '@api/recharge-request'
import { doOrderPay } from '@api/order-request'
import { wxPaymentV2 } from '@/libs/wx-utils';

export default {
    data() {
        return {
            on: '',
            order: {},
            payWay: 'wxPay',
        }
    },
    computed: {
        statusIcon() {
            if (this.order.orderStatus == 'N') return 'clock-o';
            if (this.order.orderStatus == 'T') return 'gold-coin-o';
            if (this.order.orderStatus == 'S') return 'passed-o';
            return 'clock-o'
        },
        memberInfo() {
            return this.$store.getters.memberInfo
        },
        payMethod() {
            return this.payWay;
        }
    },
    created() {
        this.on = this.$route.query.on;
        this.getOrderDetail();
        this.$store.dispatch('getMemberInfo')
    },
    methods: {
        getOrderDetail() {
            getOrderDetail(this.on).then((res) => {
                this.order = res;
                this.$nextTick(() => {
                    this.$refs.countdown.start();
                })
            })
        },
        getOrderPayConfig() {
            return new Promise((resolve, reject) => {
                let params = {
                    orderId: this.on,
                    payMethod: this.payMethod,
                }
                doOrderPay(params).then((res) => {
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        beforeOrderPay() {
            this.$toast.allowMultiple();
            if (!this.payMethod) return this.$toast('请选择支付方式');
            this.$dialog.confirm({
                title: '系统提示',
                message: '确认支付订单?'
            }).then(() => {
                const loading = this.$toast.loading('支付查询中')
                this.getOrderPayConfig().then((res) => {
                    this.afterOrderPay(res);
                }).catch((error) => {
                    //后台余额不足
                    if (error && error.errorCode == '9999') {
                        this.$dialog.confirm({
                            title: '系统提示',
                            message: '账户余额不足，是否前去充值?',
                            confirmButtonText: '去充值',
                        }).then(() => {
                            this.$goPage('user-balance')
                        })
                    }
                }).finally(() => {
                    loading.clear();
                })
            })
        },
        afterOrderPay(res) {
            if (this.payMethod === 'accountPay') {
                this.$toast('支付成功');
                this.$goPage('store-order', { index: 2 }, true);
            } else if (this.payMethod === 'wxPay') {
                wxPaymentV2(res, () => {
                    this.$toast('支付成功');
                    this.$goPage('store-order', {
                        index: 2
                    }, true);
                });
            }
        },
        cancelOrder() {
            let orderId = this.on;
            this.$dialog.confirm({
                title: '系统提示',
                message: '确认取消订单吗?'
            }).then(() => {
                let loading = this.$toast.loading(this.$config.loadingMsg);
                cancelOrder({ orderId }).then((res) => {
                    loading.clear();
                    this.$toast('操作成功')
                    this.$goPage('store')
                })
            })
        },
        onFinish() {
            this.$toast('支付超时')
            this.$goPage('store', null, true)
        }
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	flex: 1;
	position: relative;
	.bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 0;
	}
}
.pay-wrap {
	// position: absolute;
	// top: -110px;
	width: 100%;
	position: relative;
	z-index: 9;
	margin-top: 20px;
}

.status-bar {
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	/deep/ .van-count-down {
		line-height: 1;
	}

	.status-title {
		font-size: 18px;
	}

	.status-time {
		font-size: 14px;
	}
}

.bg {
	position: relative;
}

.block {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0px 2px 3px #dedede;
	margin: 12px;
	padding: 16px 10px;
	overflow: hidden;
	.order-detail {
		display: grid;
		grid-template-columns: 70px auto;
		align-items: center;
	}
	.detail-name {
		color: $tips-color;
		font-size: 13px;
	}

	.detail-value {
		color: #333333;
		font-size: 13px;

		/deep/ p {
			&:first-child {
				margin-top: 0;
			}
		}
	}

	.copy {
		display: inline-block;
		background-color: $bg-color;
		padding: 2px 5px;
		font-size: 12px;
		font: 500 3.2vw/4vw 微软雅黑;
		margin-left: 20px;
		border-radius: 999px;
	}

	.detail-grid {
		display: grid;
		grid-template-columns: repeat(2, auto);
		justify-content: space-between;
		grid-row-gap: 8px;
		.detail-name {
			color: $tips-color;
			font-size: 13px;
		}

		.detail-value {
			color: #333333;
			font-size: 13px;
			text-align: right;
		}
	}

	.product-name {
		font-size: 14px;
	}

	.product-origin {
		color: $tips-color;
		text-decoration: line-through;
	}
}

.footer-wrap {
	background-color: #fff;
	padding: 12px;
	border-top: 1px solid $border-color;
	.action {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
}
</style>
